/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.
import { Injectable } from "@angular/core";
import API, { graphqlOperation } from "@aws-amplify/api";
import { GraphQLResult } from "@aws-amplify/api/lib/types";
import { Observable } from "zen-observable-ts";

export type GetDailyHyperscanCtDataQuery = {
  __typename: "DailyHyperscanCT";
  source: string | null;
  monthToDateUploads: number | null;
  scansCompleted: number | null;
  cleanUpCompleted: number | null;
  cleanUpQueue: number | null;
  failedToUpload: number | null;
  caseUploaded: number | null;
  caseUploadedAutomatic: number | null;
  caseUploadedBoxAutomatic: number | null;
  caseUploadedBoxManual: number | null;
  caseInstanceCreationDate: string | null;
  inboundSalesforce: number | null;
  lastRefreshDate: string | null;
};

export type GetAllDailyHyperscanCtDataQuery = {
  __typename: "DailyHyperscanCT";
  source: string | null;
  monthToDateUploads: number | null;
  scansCompleted: number | null;
  cleanUpCompleted: number | null;
  cleanUpQueue: number | null;
  failedToUpload: number | null;
  caseUploaded: number | null;
  caseUploadedAutomatic: number | null;
  caseUploadedBoxAutomatic: number | null;
  caseUploadedBoxManual: number | null;
  caseInstanceCreationDate: string | null;
  inboundSalesforce: number | null;
  lastRefreshDate: string | null;
};

export type GetHyperscanProductivityQuery = {
  __typename: "Productivity";
  source: string | null;
  lastrefresh: string | null;
  reportdate: string | null;
  cleanup: Array<{
    __typename: "ProdStats";
    techname: string | null;
    scannedcases: number | null;
    mtdscannedcases: number | null;
    avgscansperhour: number | null;
    mtdavgscansperhour: number | null;
    avgtimeperscans: number | null;
    mtdavgtimeperscans: number | null;
    reportdate: string | null;
  } | null> | null;
  scanning: Array<{
    __typename: "ProdStats";
    techname: string | null;
    scannedcases: number | null;
    mtdscannedcases: number | null;
    avgscansperhour: number | null;
    mtdavgscansperhour: number | null;
    avgtimeperscans: number | null;
    mtdavgtimeperscans: number | null;
    reportdate: string | null;
  } | null> | null;
  articulation: Array<{
    __typename: "ProdStats";
    techname: string | null;
    scannedcases: number | null;
    mtdscannedcases: number | null;
    avgscansperhour: number | null;
    mtdavgscansperhour: number | null;
    avgtimeperscans: number | null;
    mtdavgtimeperscans: number | null;
    reportdate: string | null;
  } | null> | null;
};

export type GetHyperscanProductivityAvgQuery = {
  __typename: "ProductivityAVG";
  source: string | null;
  value: {
    __typename: "ProductivityAvgData";
    source: string | null;
    data: Array<{
      __typename: "ProductivityAvgMonth";
      date: string | null;
      data: {
        __typename: "ProductivityData";
        cleanup: {
          __typename: "AvgStats";
          avgscannedcases: number | null;
          avgtimeperscans: number | null;
        } | null;
        scanning: {
          __typename: "AvgStats";
          avgscannedcases: number | null;
          avgtimeperscans: number | null;
        } | null;
        articulation: {
          __typename: "AvgStats";
          avgscannedcases: number | null;
          avgtimeperscans: number | null;
        } | null;
      } | null;
    } | null> | null;
  } | null;
};

@Injectable({
  providedIn: "root"
})
export class APIService {
  async GetDailyHyperscanCtData(
    source: string
  ): Promise<GetDailyHyperscanCtDataQuery> {
    const statement = `query GetDailyHyperscanCtData($source: String!) {
        getDailyHyperscanCTData(source: $source) {
          __typename
          source
          monthToDateUploads
          scansCompleted
          cleanUpCompleted
          cleanUpQueue
          failedToUpload
          caseUploaded
          caseUploadedAutomatic
          caseUploadedBoxAutomatic
          caseUploadedBoxManual
          caseInstanceCreationDate
          inboundSalesforce
          lastRefreshDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      source
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <GetDailyHyperscanCtDataQuery>response.data.getDailyHyperscanCTData;
  }
  async GetAllDailyHyperscanCtData(
    source: string
  ): Promise<Array<GetAllDailyHyperscanCtDataQuery>> {
    const statement = `query GetAllDailyHyperscanCtData($source: String!) {
        getAllDailyHyperscanCTData(source: $source) {
          __typename
          source
          monthToDateUploads
          scansCompleted
          cleanUpCompleted
          cleanUpQueue
          failedToUpload
          caseUploaded
          caseUploadedAutomatic
          caseUploadedBoxAutomatic
          caseUploadedBoxManual
          caseInstanceCreationDate
          inboundSalesforce
          lastRefreshDate
        }
      }`;
    const gqlAPIServiceArguments: any = {
      source
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetAllDailyHyperscanCtDataQuery>>(
      response.data.getAllDailyHyperscanCTData
    );
  }
  async GetHyperscanProductivity(
    source: string
  ): Promise<Array<GetHyperscanProductivityQuery>> {
    const statement = `query GetHyperscanProductivity($source: String!) {
        getHyperscanProductivity(source: $source) {
          __typename
          source
          lastrefresh
          reportdate
          cleanup {
            __typename
            techname
            scannedcases
            mtdscannedcases
            avgscansperhour
            mtdavgscansperhour
            avgtimeperscans
            mtdavgtimeperscans
            reportdate
          }
          scanning {
            __typename
            techname
            scannedcases
            mtdscannedcases
            avgscansperhour
            mtdavgscansperhour
            avgtimeperscans
            mtdavgtimeperscans
            reportdate
          }
          articulation {
            __typename
            techname
            scannedcases
            mtdscannedcases
            avgscansperhour
            mtdavgscansperhour
            avgtimeperscans
            mtdavgtimeperscans
            reportdate
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      source
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetHyperscanProductivityQuery>>(
      response.data.getHyperscanProductivity
    );
  }
  async GetHyperscanProductivityAvg(
    source: string
  ): Promise<Array<GetHyperscanProductivityAvgQuery>> {
    const statement = `query GetHyperscanProductivityAvg($source: String!) {
        getHyperscanProductivityAVG(source: $source) {
          __typename
          source
          value {
            __typename
            source
            data {
              __typename
              date
              data {
                __typename
                cleanup {
                  __typename
                  avgscannedcases
                  avgtimeperscans
                }
                scanning {
                  __typename
                  avgscannedcases
                  avgtimeperscans
                }
                articulation {
                  __typename
                  avgscannedcases
                  avgtimeperscans
                }
              }
            }
          }
        }
      }`;
    const gqlAPIServiceArguments: any = {
      source
    };
    const response = (await API.graphql(
      graphqlOperation(statement, gqlAPIServiceArguments)
    )) as any;
    return <Array<GetHyperscanProductivityAvgQuery>>(
      response.data.getHyperscanProductivityAVG
    );
  }
}
