import { Component, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { Color, Label, BaseChartDirective } from 'ng2-charts';
import { APIService } from '../../../API.service';
import { NgbCarouselConfig, NgbCarousel } from '@ng-bootstrap/ng-bootstrap';
import { Auth } from 'aws-amplify';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {
  trigger,
  transition,
  style,
  animate,
  query,
  stagger,
  state,
  keyframes
} from "@angular/animations";

import * as API from '../../../../API';

@Component({
  selector: 'app-hyperscanct',
  templateUrl: './hyperscanct.component.html',
  providers: [NgbCarouselConfig, NgbCarousel],
  animations: [
    trigger("listAnimation", [
      transition("* => *", [
        // each time the binding value changes
        query(
          ":leave",
          [stagger(100, [animate("0.5s", style({ opacity: 0 }))])],
          { optional: true }
        ),
        query(
          ":enter",
          [
            style({ opacity: 0 }),
            stagger(100, [animate("0.5s", style({ opacity: 1 }))])
          ],
          { optional: true }
        )
      ])
    ]),
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1, 'overflow-x': 'hidden'}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    ),
    trigger('slideIn', [
      state('*', style({ 'overflow-y': 'hidden' })),
      state('void', style({ 'overflow-y': 'hidden' })),
      transition('* => void', [
        style({ height: '*' }),
        animate(250, style({ height: 0 }))
      ]),
      transition('void => *', [
        style({ height: '0' }),
        animate(250, style({ height: '*' }))
      ])
    ])
  ]
})
export class HyperScanCTComponent implements OnInit {

  @ViewChild('carousel') carousel: NgbCarousel;
  @ViewChild('carousel2') carousel2: NgbCarousel;
  @ViewChild('carousel3') carousel3: NgbCarousel;
  @ViewChildren(BaseChartDirective) charts: any;



  public lineChartTotalsData: ChartDataSets[] = [{ data: []}];
  public lineChartTotalsLabels: Label[] = [];
  public lineChartTotalsOptions: (ChartOptions) = {
    
    maintainAspectRatio:false,
    // aspectRatio: 1.5,
    plugins: {
      datalabels: {
          display: false,
      },
    },
    scales: {
  
        yAxes: [{
            ticks: { 
              fontColor: 'white', 
              fontSize:14 + (14 * ((window.innerHeight - 1080) / 1080)),
              padding: 20 + (20 * ((window.innerHeight - 1080) / 1080))
            },
          }],
  
        xAxes: [{
          ticks: { 
            fontColor: 'white',
            fontSize:14 + (14 * ((window.innerHeight - 1080) / 1080)),
            maxRotation: 0,
              minRotation: 0
          },
        }],
      },
      legend: {
        display: true,
        labels: {
            fontColor: 'white',
            fontSize:13 + (13 * ((window.innerHeight - 1080) / 1080))
        }
      },
  
   };
  
  public lineChartTotalsColors: Color[] = [{},];
   public lineChartTotalsLegend = true;
   public lineChartTotalsType = 'line';


 public pieChartData: ChartDataSets[] = [
  { data: []}
]
public pieChartLabels: Label[] = [];
public pieChartOptions: (ChartOptions) = {
  maintainAspectRatio:false,
  plugins: {
    datalabels: {
        color:'white',
        font: {size:20 + (20 * ((window.innerHeight - 1080) / 1080))}
    },
  },
  legend:{
    labels:{
      fontColor:'white',
      fontSize:12 + (12 * ((window.innerHeight - 1080) / 1080))
    }
  }
};
public pieChartType = 'doughnut';

    // scan average time line chart
  public lineChartTimeTotalsData: ChartDataSets[] = [{ data: []}];
  public lineChartTimeTotalsLabels: Label[] = [];
  public lineChartTimeTotalsOptions: (ChartOptions) = {
    maintainAspectRatio:false,
    //aspectRatio: 1.5,
    plugins: {
      datalabels: {
          display: false,
      },
    },
    scales: {

        yAxes: [{
            ticks: { 
              fontColor: 'white', 
              fontSize:14 + (14 * ((window.innerHeight - 1080) / 1080)),
              padding: 20 + (20 * ((window.innerHeight - 1080) / 1080)) 
            },
          }],

        xAxes: [{
          ticks: { 
            fontColor: 'white',
            fontSize:14 + (14 * ((window.innerHeight - 1080) / 1080)),
            maxRotation: 0,
            minRotation: 0
           },
        }],
      },
      legend: {
        display: true,
        labels: {
            fontColor: 'white',
            fontSize:13 + (13 * ((window.innerHeight - 1080) / 1080))
        }
      },

   };

  public lineChartTimeTotalsColors: Color[] = [
    {

    },
  ];
   public lineChartTimeTotalsLegend = true;
   public lineChartTimeTotalsType = 'line';



        // bottom scan times radar chart
        public radarChartBottomTimeData: ChartDataSets[] = [{ data: []}]
        public radarChartBottomTimeLabels: Label[] = [];
        public radarChartBottomTimeOptions: (ChartOptions) = {
          plugins: {
            datalabels: {
                display: false,
            },
          },

          scale:{
            ticks:{
              showLabelBackdrop:false
            },
            gridLines:{
              color:'#a3a3a3'
            },
            angleLines: {
              display: true,
            },
            pointLabels: {
              fontColor:'white',
              fontSize:14 + (14 * ((window.innerHeight - 1080) / 1080))
            },

          },
          scales: {

            },
        };
        public radarChartBottomTimeType = 'radar';
        public radarChartBottomTimeLegend = false;



        // bottom scan times radar chart
        public barChartTopBottomData: ChartDataSets[] = [
          { data: [
          
          ], label: 'TopBottom Performers' },
      
        ]
        public barChartTopBottomLabels: Label[] =  [];
        public barChartTopBottomOptions: (ChartOptions) = {
        };
        public barChartTopBottomType = 'horizontalBar';
        public barChartTopBottomLegend = false;






  // public lineChartPlugins = [];

  // carouselConfig:any
  constructor(private apiService: APIService, private router: Router){ 
  // {
  //   console.log(this.carousel)
  //   //this.carouselConfig = carouselConfig;
  //   // this.carouselConfig.interval = 3000;
  //   // this.carouselConfig.wrap = true;
  //   // this.carouselConfig.keyboard = false;
  //   // this.carouselConfig.pauseOnHover = false;
  //   // this.carouselConfig.showNavigationArrows = false;
  //   // this.carouselConfig.showNavigationIndicators = false;
  }  

  heading = 'HyperScanCT Dashboard';
  subheading = '';
  icon = 'pe-7s-plane icon-gradient bg-tempting-azure';

  public hyperscanct_datasets = [];
  public prod_dat = [];
  public prod_dat_avg = [];
  public sourceData = [];
  public emp_allscans:any;
  public emp_clnart:any;
  tbavg = 0;
  avgScanTimeToday = 0;
  avgScanTimeMTD = 0;
  avgScansPerHour = 0;
  avgScansPerHourMTD = 0;
  topbottomscanstages = [];
  currentsource = 'Total';
  currentstage = 'scanning';
  sourceindex = 0;
  hackslideint: any;
  quota = 70;

  stages = [
    'scanning',
    'cleanup',
    'articulation'
  ]

  public formatdate = 'dd/MM/yyyy';

  async ngOnInit() {

    //window.innerHeight
      console.log()

      // var canvas:any = document.getElementById("myChart6") as HTMLCanvasElement;
      // if(canvas){
      //   var context = canvas.getContext("2d");

      //   var vm = this;
      //   let h = context.canvas.scrollHeight;
  
      //   this.lineChartTotalsOptions.scales.yAxes[0].ticks.fontSize = 10 * ((h - 1080) / 1080)
      // }


    // set the intial manual change of the scan data to 3 mins
    setTimeout(()=>{ this.carousel.next() }, 180000)

    // redirect to login page if not logged in
    Auth.currentSession()
    .then(async (data) => {
      await this.getHyperScanData();
      await this.getProductivityData();
      await this.calculateTotalScans();
      this.initProdDat();
      this.jogslides();
      this.hackslides();

    })
    .catch(err => {
      console.log(err);
      this.router.navigate(['/pages/login']);
    });

    //data refresh
    setInterval(() => { 
      Auth.currentSession()
      .then(async (data) => {
        await this.getHyperScanData();
        await this.getProductivityData();
        await this.calculateTotalScans();
        this.initProdDat()
        this.jogslides();
        this.hackslides();
      })
      .catch(err => {
        console.log(err);
        this.router.navigate(['/pages/login']);
      });
    }, 600000);

    // reload page once every 24 hours to keep away longevity issues
    setTimeout(
      function(){ 
      location.reload(); 
    }, 86400000);
  }

  async initProdDat(){

      var avg = await this.calculateAvgs();
      await this.topBottomPerformersInit(avg);

      await this.avgScansChartsInit();
      await this.avgTimeChartsInit();
      await this.scansPerSourceInit();
      await this.bottomScanTimeInit();

      this.charts.forEach((child) => {
          if(child){
            if(child.chart)
              child.chart.update()
          }
          
      });
      

  }

  async calculateAvgs(){

    this.avgScanTimeToday = 0;
    this.avgScanTimeMTD = 0;
    this.avgScansPerHour = 0;
    this.avgScansPerHourMTD = 0;

      // get the averages for all users
      var avg = 0;
      var personcounter = 0;

      var dat;
      for(var i = 0; i < this.prod_dat.length; i++){
        if(this.prod_dat[i].source == this.currentsource)
          dat = this.prod_dat[i][this.currentstage]
      }

      if(!dat)
        dat = [];

      for(var i = 0; i < dat.length; i++){
        if(dat[i])
          avg += dat[i].mtdscannedcases

        personcounter++;

        if(dat[i]){
        
            if(dat[i].avgtimeperscans >= 0)
              this.avgScanTimeToday += Math.round(dat[i].avgtimeperscans);

            if(dat[i].mtdavgtimeperscans >= 0)
              this.avgScanTimeMTD += Math.round(dat[i].mtdavgtimeperscans);

            this.avgScansPerHour += Math.round(dat[i].avgscansperhour);
            this.avgScansPerHourMTD += Math.round(dat[i].mtdavgscansperhour);
        }
      }

      if(dat.length)
        avg = Math.round(avg/dat.length);

      this.avgScanTimeToday = Math.ceil(this.avgScanTimeToday / personcounter) * 1000;
      this.avgScanTimeMTD = Math.ceil(this.avgScanTimeMTD / personcounter) * 1000;
      this.avgScansPerHour = Math.round(this.avgScansPerHour / personcounter);
      this.avgScansPerHourMTD = Math.round(this.avgScansPerHourMTD / personcounter);

      if(!this.avgScansPerHour)
        this.avgScansPerHour = 0;

      if(!this.avgScansPerHourMTD)
        this.avgScansPerHourMTD = 0;

      return avg;

  }


  async topBottomPerformersInit(avg){

      // build the gradients

      var canvas = document.getElementById('topbottomscanner') as HTMLCanvasElement

      if(canvas){
        
          var ctx = canvas.getContext("2d");

          let gradientStroke = ctx.createLinearGradient(0, 160, 0, 330);
          gradientStroke.addColorStop(0, "#E2F0CB");
          gradientStroke.addColorStop(1, "#FF9AA2");
          
          let gsh = ctx.createLinearGradient(0, 0, 10, 170);
          gsh.addColorStop(0, "#B5EAD7");
          gsh.addColorStop(1, "#C7CEEA");


          var dat;

          //reorder prod data in desc order
          for(var i = 0; i < this.prod_dat.length; i++){
            if(this.prod_dat[i].source == this.currentsource)
              dat = this.prod_dat[i][this.currentstage]

          }

          if(!dat)
            dat = [];

          dat.sort(function(a,b){return b.mtdscannedcases - a.mtdscannedcases});


      //     // set the top/bottom scan performers data
          var data = [];
          var labels = [];
          var min = 0;

          if(dat.length > 0){

            var count = 0;

            for(var i = 0; i < dat.length; i++){

              if(count < 5){
                if(dat[i]){
                  data.push( dat[i].mtdscannedcases - avg);
                  labels.push(this.toTitleCase(dat[i].techname))
                  count++;
                }
              } else {

                if(dat.length >= 10){

                  while(count <= 9){

                    data.push(dat[dat.length-5] ? -Math.abs(dat[dat.length-5].mtdscannedcases - avg) : 0); 
                    labels.push(dat[dat.length-5] ? this.toTitleCase(dat[dat.length-5].techname) : '');
          
                    data.push(dat[dat.length-4] ? -Math.abs(dat[dat.length-4].mtdscannedcases - avg) : 0); 
                    labels.push(dat[dat.length-4] ? this.toTitleCase(dat[dat.length-4].techname) : '');
          
                    data.push(dat[dat.length-3] ? -Math.abs(dat[dat.length-3].mtdscannedcases - avg) : 0); 
                    labels.push(dat[dat.length-3] ? this.toTitleCase(dat[dat.length-3].techname) : '');
          
                    data.push(dat[dat.length-2] ? -Math.abs(dat[dat.length-2].mtdscannedcases - avg) : 0); 
                    labels.push(dat[dat.length-2] ? this.toTitleCase(dat[dat.length-2].techname) : '');
          
                    data.push(dat[dat.length-1] ? -Math.abs(dat[dat.length-1].mtdscannedcases - avg) : 0); 
                    labels.push(dat[dat.length-1] ? this.toTitleCase(dat[dat.length-1].techname) : '');
                    count += 5;
                  }

        
                } else {
        
                  if(count <= 10){

                    if(dat[i]){
                      data.push(dat[i] ? -Math.abs(dat[i].mtdscannedcases - avg) : 0); 
                      labels.push(dat[i] ? this.toTitleCase(dat[i].techname) : '');
                      count ++;
                    } else {
                      data.push(0); 
                      labels.push('');
                      count ++;
                    }
                  }
                }
              }
            }

            min = dat[dat.length-1].mtdscannedcases - avg
            
          }


          this.barChartTopBottomData = [
            { data: data, label: 'Scan Count',
            backgroundColor:[
              gsh,
              gsh,
              gsh,
              gsh,
              gsh,
              gradientStroke,
              gradientStroke,
              gradientStroke,
              gradientStroke,
              gradientStroke,
            ] },
        
          ];


          // set the labels as tech names
          this.barChartTopBottomLabels = labels

          // set the options for the top/bottom chart, including the 0 axis data hack
          this.barChartTopBottomOptions = {
            //maintainAspectRatio:false,
            //aspectRatio: 0.765,
            plugins: {
              datalabels: {
                  display: false,
              },
            },
            scales: {
                yAxes: [{
                  ticks: {
                    fontColor:'White',
                    fontSize:15 + (15 * ((window.innerHeight - 1080) / 1080))
                  }
                }],
        
                xAxes: [{
                  ticks: {
                    fontColor:'White',
                    fontSize:15 + (15 * ((window.innerHeight - 1080) / 1080)),
                    callback: function(value:any, index, values) {
                      return value + avg < 0 ? 0 : value + avg
                    },
                    min: min
                  }
                  
                }],
              },
          }
    }

  }

  async avgScansChartsInit(){

    var sdata = [];
    var cdata = [];
    var adata = [];
    var labs = [];

    var dat = [];

    for(var i = 0; i < this.prod_dat_avg.length; i++){
      if(this.prod_dat_avg[i].source == this.currentsource)
        if(this.prod_dat_avg[i].value)
          dat = this.prod_dat_avg[i].value.data
    }


    for(var i = 0; i < dat.length; i++){

      var date = new Date(dat[i].date).toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        timeZone: 'UTC'
      });

      labs.push(date)

      if(dat[i].data.scanning){
        if(!dat[i].data.scanning.avgscannedcases){
          sdata.push(0)
        } else {
          sdata.push(dat[i].data.scanning.avgscannedcases)
        }
        
      } else {
        sdata.push(0)
      }

      if(dat[i].data.cleanup){
        if(!dat[i].data.cleanup.avgscannedcases){
          cdata.push(0)
        } else {
          cdata.push(dat[i].data.cleanup.avgscannedcases)
        }
      } else {
        cdata.push(0)
      }
      
      if(dat[i].data.articulation){

        if(!dat[i].data.articulation.avgscannedcases){
          adata.push(0)
        } else {
          adata.push(dat[i].data.articulation.avgscannedcases)
        }
        
      } else {
        adata.push(0)
      }
    }

    sdata = sdata.reverse();
    cdata = cdata.reverse();
    adata = adata.reverse();
    labs = labs.reverse();

    this.lineChartTotalsData = [
      {data: sdata, label: 'Scanning', fill: false, borderColor:'#80b6f4'},
      {data: cdata, label: 'Cleanup', fill: false, borderColor:'#A1DE93'},
     
    ]

    if(this.currentsource != 'Byte')
      this.lineChartTotalsData.push({data: adata, label: 'Articulation', fill: false, borderColor:'#F47C7C'});


    // only show a few labels to avoid crowding
    var i = labs.length;
    while (i--) {
      if(i != labs.length && i !== 0 && i % 3 != 0)
        labs[i] = '';
    }

    this.lineChartTotalsLabels = labs


  }

  async avgTimeChartsInit(){

    var sdata = [];
    var cdata = [];
    var adata = [];
    var labs = [];

    var dat = [];

    for(var i = 0; i < this.prod_dat_avg.length; i++){
      if(this.prod_dat_avg[i].source == this.currentsource)
        if(this.prod_dat_avg[i].value)
          dat = this.prod_dat_avg[i].value.data
    }


    for(var i = 0; i < dat.length; i++){

      var date = new Date(dat[i].date).toLocaleDateString('en-US', {
        day: '2-digit',
        month: '2-digit',
        timeZone: 'UTC'
      });

      labs.push(date)

      if(dat[i].data.scanning){
        sdata.push(dat[i].data.scanning.avgtimeperscans / 60)
      } else {
        sdata.push(0)
      }

      if(dat[i].data.cleanup){
        cdata.push(dat[i].data.cleanup.avgtimeperscans / 60)
      } else {
        cdata.push(0)
      }
      
      if(dat[i].data.articulation){
        adata.push(dat[i].data.articulation.avgtimeperscans / 60)
      } else {
        adata.push(0)
      }
    }

    sdata = sdata.reverse();
    cdata = cdata.reverse();
    adata = adata.reverse();
    labs = labs.reverse();

    this.lineChartTimeTotalsData = [
      {data: sdata, label: 'Scanning', fill: false, borderColor:'#FECBA5'},
      {data: cdata, label: 'Cleanup', fill: false, borderColor:'#B3C8C8'},
    ]

    if(this.currentsource != 'Byte')
      this.lineChartTimeTotalsData.push({data: adata, label: 'Articulation', fill: false, borderColor:'#B88BAD'});

    // only show a few labels to avoid crowding
    var i = labs.length;
    while (i--) {
      if(i != labs.length && i !== 0 && i % 3 != 0)
        labs[i] = '';
    }

    this.lineChartTimeTotalsLabels = labs


  }

  async scansPerSourceInit(){

    var dat = [];
    var labels = [];
    
    for(var i = 0; i < this.prod_dat.length; i++){
      
      if((this.currentstage == 'articulation' && this.prod_dat[i].source == 'Byte') || this.prod_dat[i].source == 'Total'){
      } else {
        labels.push(this.prod_dat[i].source)

        var count = 0;

        if(this.prod_dat[i][this.currentstage]){
          for(var j = 0; j < this.prod_dat[i][this.currentstage].length; j++){
            count += this.prod_dat[i][this.currentstage][j].mtdscannedcases
          }
        }

        dat.push(count);
      }
    }

    this.pieChartData = [{
      data: dat
    }]

    this.pieChartLabels = labels;

    this.pieChartData[0].backgroundColor = ["#6CB2D1", "#90C978", "#DF9881"];
    this.pieChartData[0].borderColor = '#292929'


    this.sourceData = [];
    for(var i = 0; i < dat.length; i++){
      this.sourceData.push({source:labels[i], scans:dat[i]})
    }

  }

  async bottomScanTimeInit(){

    var canvas4 = document.getElementById('myChart10') as HTMLCanvasElement

    if(canvas4){

        var ctx4 = canvas4.getContext("2d");

        let grad6 = ctx4.createLinearGradient(200, 0, 0, 100);
        grad6.addColorStop(0, "#F47C7C");
        grad6.addColorStop(1, "#F7F48B");

        var dat;

        //reorder prod data in desc order
        for(var i = 0; i < this.prod_dat.length; i++){
          if(this.prod_dat[i].source == this.currentsource)
            dat = this.prod_dat[i][this.currentstage]
        }

        var data = [];
        var labels = [];
        if(dat){
          dat.sort(function(a,b){return Math.abs(b.mtdavgtimeperscans) - Math.abs(a.mtdavgtimeperscans)});

          data = [
            dat[0] ? Math.abs(dat[0].mtdavgtimeperscans) : 0,
            dat[1] ? Math.abs(dat[1].mtdavgtimeperscans) : 0,
            dat[2] ? Math.abs(dat[2].mtdavgtimeperscans) : 0,
            dat[3] ? Math.abs(dat[3].mtdavgtimeperscans) : 0,
            dat[4] ? Math.abs(dat[4].mtdavgtimeperscans) : 0,
          ];

          labels = [
            dat[0] ? this.toTitleCase(dat[0].techname) +  ' | ' + this.fancyTimeFormat(Math.round(Math.abs(dat[0].mtdavgtimeperscans))) : '',
            dat[1] ? this.toTitleCase(dat[1].techname) +  ' | ' + this.fancyTimeFormat(Math.round(Math.abs(dat[1].mtdavgtimeperscans))) : '',
            dat[2] ? this.toTitleCase(dat[2].techname) +  ' | ' + this.fancyTimeFormat(Math.round(Math.abs(dat[2].mtdavgtimeperscans))) : '',
            dat[3] ? this.toTitleCase(dat[3].techname) +  ' | ' + this.fancyTimeFormat(Math.round(Math.abs(dat[3].mtdavgtimeperscans))) : '',
            dat[4] ? this.toTitleCase(dat[4].techname) +  ' | ' + this.fancyTimeFormat(Math.round(Math.abs(dat[4].mtdavgtimeperscans))) : '',
          ];
        }
          




        // set the top/bottom scan performers data
        this.radarChartBottomTimeData = [
          { data: data, label: 'Scan Count' },
        ];

        // set the labels as tech names
        this.radarChartBottomTimeLabels = labels

        this.radarChartBottomTimeData[0].borderColor = grad6
        this.radarChartBottomTimeData[0].backgroundColor = grad6;
        this.radarChartBottomTimeData[0].pointBorderColor = 'white'
        this.radarChartBottomTimeData[0].pointBackgroundColor = 'white'

        if(dat){
          if(dat[0].avgtimeperscans < 5){
            if(!this.radarChartBottomTimeOptions.scales.ticks)
              this.radarChartBottomTimeOptions.scales.ticks = {}
      
            this.radarChartBottomTimeOptions.scales.ticks.max = 100; 
          }
        }
    }

  }

  async getHyperScanData(){

    this.hyperscanct_datasets = await this.apiService.GetAllDailyHyperscanCtData("AllSource");

    // no data generally means an error with the auth, maybe login expired
    // if(!this.hyperscanct_datasets){
    //   this.router.navigate(['/pages/login']);
    // }

    if(this.hyperscanct_datasets){
      for(var i=0; i < this.hyperscanct_datasets.length; i++){
        let date = new Date(this.hyperscanct_datasets[i].caseInstanceCreationDate);
        this.hyperscanct_datasets[i].caseInstanceCreationDate =  (date.getUTCMonth() + 1) + '/' + date.getUTCDate() + '/' + date.getUTCFullYear();
      }
    } else {

      // Nothing from the db, show 0's
      let date = new Date();
      this.hyperscanct_datasets = [{
        source: '',
        caseInstanceCreationDate: (date.getUTCMonth() + 1) + '/' + date.getUTCDate() + '/' + date.getUTCFullYear(),
        lastRefreshDate: '',
        inboundSalesforce:0,
        scansCompleted:0,
        cleanUpQueue:0,
        cleanUpCompleted:0,
        caseUploaded:0,
        failedToUpload:0,
        caseUploadedAutomatic:0,
        caseUploadedBoxAutomatic:0,
        caseUploadedBoxManual:0,
        monthToDateUploads:0
      }];
    }

    // do this step here, after we have gathered data
    //this.setupCarousel();

    this.jogslides();

    setTimeout(() => { 
      this.getHyperScanData();
    }, 600000);

  }

  async getProductivityData(){
    this.prod_dat = await this.apiService.GetHyperscanProductivity("Productivity");
    this.prod_dat_avg = await this.apiService.GetHyperscanProductivityAvg('ProductivityAVG');
  }

  jogslides(){
    setTimeout(() => { 
      this.carousel.pause();
      this.carousel.cycle();
      this.carousel2.pause();
      this.carousel2.cycle();
      this.carousel3.pause();
      //this.carousel3.cycle();
    }, 1000);
  }

  hackslides(){

    // stop old timer
    clearInterval(this.hackslideint);

    // start new
    this.hackslideint = setInterval(async () => {

      await this.setStage(null);
      
      var avg = await this.calculateAvgs();
      this.topBottomPerformersInit(avg);
      this.scansPerSourceInit();
      this.bottomScanTimeInit();
      

    //}, 89333)
    }, 30000)
  }

  setStage(index){

    // can force a stage
    if(index != null){
      this.currentstage = this.stages[index]

      // skip if no data
      if(this.prod_dat[this.sourceindex]){
        if(!this.prod_dat[this.sourceindex][this.currentstage])
          this.setStage(null);
      }

    } else {

      // get the current stage
      var stageindex = this.stages.indexOf(this.currentstage)

      // set the current stage, reset to start if at the end of the list
      stageindex >= this.stages.length-1 ? this.currentstage = this.stages[0] : this.currentstage = this.stages[stageindex + 1]
  
      // byte specifically has no articulation step, hard code this logic in
      if(this.currentstage == 'articulation' && this.currentsource == 'Byte')
        this.currentstage = this.stages[0]

      // reset when at the end of loop
      if(this.sourceindex >= this.prod_dat.length)
        this.sourceindex = 0;

      // skip if no data
      if(this.prod_dat[this.sourceindex]){
        if(!this.prod_dat[this.sourceindex][this.currentstage])
          this.setStage(null);
      }

    }

    return;
  }

  sourceSlide(e){

    this.sourceindex++;
    
    if(this.sourceindex >= this.prod_dat.length)
      this.sourceindex = 0;

    if(this.prod_dat[this.sourceindex])
      this.currentsource = this.prod_dat[this.sourceindex].source

    this.setStage(0);
    this.initProdDat();

  }

  dashboardSlide(e){
    if(e.current == 'ngb-slide-0'){
      this.carousel3.pause();this.carousel2.cycle();
      setTimeout(()=>{ this.carousel.next(); }, 180000)
    } else {
      this.carousel2.pause();this.carousel3.cycle();
      setTimeout(()=>{ this.carousel.next();this.carousel3.pause();this.carousel2.cycle(); }, 360000)
    }
      
  }

  async calculateTotalScans(){
    this.emp_allscans = []
    this.emp_clnart = []
    var date = new Date().setHours(0,0,0,0);

    // loop through prod_dat, all source
    for(var i = 1; i < this.prod_dat.length; i++){

        // loop through scanning and add
        var phases = ['scanning']
        for(var m = 0; m < phases.length; m++){
          if(this.prod_dat[i][phases[m]]){
            for(var k = 0; k < this.prod_dat[i][phases[m]].length; k++){

              if(this.prod_dat[i][phases[m]][k].reportdate){
                var dat = new Date(this.prod_dat[i][phases[m]][k].reportdate);

                dat.setHours(dat.getHours()+8);
                dat.setHours(0,0,0,0);
                var repd = dat.getTime();
              }
              
              var found = 0;
              // loop through newly added data to check for dupes
              for(var l = 0; l < this.emp_allscans.length; l++){
                
                // if name already exists, append to scanned cases, otherwise add new user to array
                if(this.emp_allscans[l].techname == this.prod_dat[i][phases[m]][k].techname.toLowerCase()){
                  found = 1;
                  
                  if(this.prod_dat[i][phases[m]][k].scannedcases && repd == date)
                    this.emp_allscans[l].scannedcases += this.prod_dat[i][phases[m]][k].scannedcases
                }
              }
              
              if(found == 0){

                if(this.prod_dat[i][phases[m]][k].scannedcases && repd == date){
                  this.emp_allscans.push({
                    techname: this.prod_dat[i][phases[m]][k].techname.toLowerCase(),
                    scannedcases: this.prod_dat[i][phases[m]][k].scannedcases
                  })  
                }
              }
            }
          }

        }

        // loop through clean/art and add
        var phases = ['cleanup', 'articulation']
        for(var m = 0; m < phases.length; m++){
          if(this.prod_dat[i][phases[m]]){
            for(var k = 0; k < this.prod_dat[i][phases[m]].length; k++){

              if(this.prod_dat[i][phases[m]][k].reportdate){
                var dat = new Date(this.prod_dat[i][phases[m]][k].reportdate);

                dat.setHours(dat.getHours()+8);
                dat.setHours(0,0,0,0);
                var repd = dat.getTime();
              }
              
              var found = 0;
              // loop through newly added data to check for dupes
              for(var l = 0; l < this.emp_clnart.length; l++){
                
                // if name already exists, append to scanned cases, otherwise add new user to array
                if(this.emp_clnart[l].techname == this.prod_dat[i][phases[m]][k].techname.toLowerCase()){
                  found = 1;
                  
                  if(this.prod_dat[i][phases[m]][k].scannedcases && repd == date)
                    this.emp_clnart[l].scannedcases += this.prod_dat[i][phases[m]][k].scannedcases
                }
              }
              
              if(found == 0){

                if(this.prod_dat[i][phases[m]][k].scannedcases && repd == date){
                  this.emp_clnart.push({
                    techname: this.prod_dat[i][phases[m]][k].techname.toLowerCase(),
                    scannedcases: this.prod_dat[i][phases[m]][k].scannedcases
                  })  
                }
              }
            }
          }

        }
       

    }
    
    this.emp_allscans.sort((a, b) => {
      // Use toUpperCase() to ignore character casing
      const scannerA = a.scannedcases;
      const scannerB = b.scannedcases;
    
      let comparison = 0;
      if (scannerA < scannerB) {
        comparison = 1;
      } else if (scannerA > scannerB) {
        comparison = -1;
      }
      return comparison;
    });

    this.emp_clnart.sort((a, b) => {
      // Use toUpperCase() to ignore character casing
      const scannerA = a.scannedcases;
      const scannerB = b.scannedcases;
    
      let comparison = 0;
      if (scannerA < scannerB) {
        comparison = 1;
      } else if (scannerA > scannerB) {
        comparison = -1;
      }
      return comparison;
    });


    // if no data, put something that says so
    if(this.emp_allscans.length == 0)
      this.emp_allscans.push({techname:'No scans'})

    if(this.emp_clnart.length == 0)
      this.emp_clnart.push({techname:'No cleanup'})

    return;
  }



  toTitleCase(phrase) {
    return phrase
      .replace(/[^\w\s]/gi, ' ')
      .toLowerCase()
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  fancyTimeFormat(duration){   
      // Hours, minutes and seconds
      var hrs = ~~(duration / 3600);
      var mins = ~~((duration % 3600) / 60);
      var secs = ~~duration % 60;

      // Output like "1:01" or "4:03:59" or "123:03:59"
      var ret = "";

      if (hrs > 0) {
          ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
      }

      ret += "" + mins + ":" + (secs < 10 ? "0" : "");
      ret += "" + secs;
      return ret;
  }
  
}
