import { Component, OnInit, NgZone } from '@angular/core';
import { Auth } from 'aws-amplify';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { onAuthUIStateChange } from '@aws-amplify/ui-components'  

@Component({
  selector: 'app-login-boxed',
  templateUrl: './login-boxed.component.html',
  styles: []
})
export class LoginBoxedComponent implements OnInit {

  constructor(private router: Router, private ngZone: NgZone) {
    onAuthUIStateChange(newAuthState  => {
      this.handleCustomAuthState(newAuthState)
    })
   }

  ngOnInit() {


    // Auth.currentSession()
    // .then(data => {
    //   console.log(data)

    //   this.router.navigate(['']);
      
    // })
    // .catch(err => {
    //   //this.router.navigate(['/pages/login']);
    // });

  }

  handleCustomAuthState(authState: any) {
    //this.router.navigate(['']);
    console.log(authState)
    if(authState == 'signedin'){
      Auth.currentSession().then(data => {
        this.ngZone.run(() => this.router.navigate([''])).then();
      }).catch(err => {
        console.log(err);
      });
      //this.router.navigate(['dashboard']);
    }
  }

}
