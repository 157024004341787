/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:84f8e0db-18c1-40f2-aede-dc8243a2df3a",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_BYccWNM2p",
    "aws_user_pools_web_client_id": "2bp8t0vhq71278a8k63i1bqd0r",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://2bejeje2fzhltopr4dm5artxhm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-riimd6va2vekpazfadrbdhasta"
};


export default awsmobile;
