import {Component} from '@angular/core';
import { APIService } from './API.service';
//import { AmplifyService } from 'aws-amplify-angular';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
})

export class AppComponent {
  title = 'Glidewell Dashboards';

  constructor() {}

  GetDailyHyperscanCtData() {
    //this.apiService.GetDailyHyperscanCtData("Byte");
  }
}
